import { DefaultTheme, theme } from '@chakra-ui/core';

const customTheme: DefaultTheme = {
    ...theme,
    breakpoints: ['360px', '720px', '1024px', '1366px'],
    fonts: {
        body: 'CentraNube',
        heading: 'CentraNube',
        mono: 'CentraNube'
    },
    fontWeights: {
        ...theme.fontWeights,
        normal: 400,
        medium: 500,
        bold: 500
    },
    colors: {
        ...theme.colors,
        black: '#000',
        white: '#FFFFFF',
        gray: {
            ...theme.colors.gray,
            500: '#F1F5F7',
            700: '#a0aec0',
            800: '#555555'
        },
        blue: {
            ...theme.colors.blue,
            500: '#046FD9'
        },
        green: {
            ...theme.colors.green,
            500: '#1EBD94'
        },
        purple: {
            ...theme.colors.purple,
            500: '#42478B',
            700: '#363A72'
        },
        pink: {
            ...theme.colors.pink,
            500: '#E20A40'
        },
        yellow: {
            ...theme.colors.yellow,
            500: '#F2B502'
        }
    }
};

export default customTheme;
