import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Image, Spinner, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import checkSuccess from '../../../assets/images/check-success.png';
import logo from '../../../assets/images/wsuite-logo.png';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/TextArea';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import { apiEflorista } from '../../../shared/services/apiGateway';
import UploadDeliveryImage from '../components/UploadDeliveryImage';

const DeliveryIndex: React.FC = () => {
    const formRef: any = useRef<FormHandles>(undefined);

    const toast = useToast();
    const navigate = useNavigate();

    const { storeId, orderId } = useParams() as any;

    const [deliveryman, setDeliveryman] = useState(null);
    const [isDestinatary, setIsDestinatary] = useState(true);
    const [order, setOrder] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isSucessSubmit, setIsSuccessSubmit] = useState(false);

    const [dataUri, setDataUri] = useState('');
    const [deliverymens, setDeliverymens] = useState([]);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiEflorista(storeId).put(
                    `/orders/delivery/${storeId}/${orderId}`,
                    {
                        is_destinatary: isDestinatary,
                        ...formData,
                        photo_url: dataUri
                    }
                );

                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess('Envio Realizado', '', toast);
                    setIsSuccessSubmit(true);
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [formRef, storeId, orderId, isDestinatary, dataUri]
    );

    const handleSubmitDeliveryman = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await apiEflorista(storeId).put(`/orders/${orderId}/deliverymen`, {
                deliverymen_id: deliveryman
            });

            if (response.status === HTTP.STATUS.SUCCESS) {
                setIsSuccessSubmit(true);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                FormHelper.showErrors(formRef, error, toast);
            }
        }

        setIsLoading(false);
    }, [formRef, storeId, orderId, deliveryman]);

    useEffect(() => {
        apiEflorista(storeId)
            .get('/users/deliverymens')
            .then((response) => {
                setDeliverymens(response.data);
            });

        apiEflorista(storeId)
            .get(`/orders/${orderId}`)
            .then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setOrder(response.data);
                }
            });
    }, [storeId, orderId]);

    console.log(order);

    if (!order) return;

    return order.deliveryman ? (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            {!isSucessSubmit && (
                <Flex width="100%" flexDirection="column" alignItems="center">
                    <Flex
                        width="100%"
                        height="72px"
                        backgroundColor="#42478B"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logo} width="64px" height="40px" />
                    </Flex>

                    <Flex
                        width="100%"
                        color="#7A7878"
                        justifyContent="center"
                        fontWeight="600"
                        mt="8px"
                    >
                        <Text>Pedido</Text>
                        <Text ml="8px">{order?.code}</Text>
                    </Flex>

                    <Flex
                        width="100%"
                        color="#7A7878"
                        justifyContent="center"
                        fontWeight="500"
                        my="8px"
                    >
                        <Text>Entregador:</Text>
                        <Text ml="8px">{order?.deliveryman.name}</Text>
                    </Flex>

                    <Flex
                        width={['312px', '360px', '400px']}
                        color="#363636"
                        flexDirection="column"
                        justifyContent="center"
                        mb="16px"
                    >
                        <Text fontWeight="500">Entrega em Mãos do destinatário(a)?</Text>
                        <Flex>
                            <Checkbox
                                name="is_promotional"
                                isChecked={isDestinatary === false}
                                onChange={() => setIsDestinatary(!isDestinatary)}
                                value="0"
                                mr="16px"
                                size="lg"
                            >
                                <Text fontSize="14px">Não</Text>
                            </Checkbox>
                            <Checkbox
                                name="is_promotional"
                                isChecked={isDestinatary}
                                onChange={() => setIsDestinatary(!isDestinatary)}
                                value="1"
                                mr="16px"
                                size="lg"
                            >
                                <Text fontSize="14px">Sim</Text>
                            </Checkbox>
                        </Flex>
                    </Flex>

                    <Flex
                        width={['312px', '360px', '400px']}
                        color="#363636"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Flex display={isDestinatary === false ? 'flex' : 'none'}>
                            <Input
                                name="receiver_name"
                                label="Nome de quem recebeu"
                                size="sm"
                                mb="4px"
                            />
                        </Flex>

                        <TextArea name="observations" label="Observação" size="sm" mb="4px" />
                    </Flex>

                    <Flex width="100%" justifyContent="center" my="24px">
                        <UploadDeliveryImage setFunction={setDataUri} />
                    </Flex>

                    <Button
                        type="submit"
                        width={['312px', '360px', '400px']}
                        backgroundColor="#F2B502"
                        height="48px"
                        mt="8px"
                        borderRadius="4px"
                        mb="24px"
                        isDisabled={isLoading}
                    >
                        <Flex fontSize="14px">
                            {isLoading ? <Spinner /> : <Text>Confirmar</Text>}
                        </Flex>
                    </Button>
                </Flex>
            )}

            {isSucessSubmit && (
                <Flex width="100vw" height="100vh" flexDirection="column">
                    <Flex
                        width="100%"
                        height="72px"
                        backgroundColor="#42478B"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logo} width="64px" height="40px" />
                    </Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Image width="120px" height="120px" src={checkSuccess} />

                        <Text fontWeight="500" mt="8px">
                            Confirmação Enviada
                        </Text>
                    </Flex>
                </Flex>
            )}
        </Form>
    ) : (
        <Form
            ref={formRef}
            onSubmit={handleSubmitDeliveryman}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            {!isSucessSubmit && (
                <Flex width="100%" flexDirection="column" alignItems="center">
                    <Flex
                        width="100%"
                        height="72px"
                        backgroundColor="#42478B"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logo} width="64px" height="40px" />
                    </Flex>

                    <Flex
                        width="100%"
                        color="#7A7878"
                        justifyContent="center"
                        fontWeight="600"
                        mt="8px"
                    >
                        <Text>Pedido</Text>
                        <Text ml="8px">{order.code}</Text>
                    </Flex>

                    <Flex
                        width="100%"
                        color="#363636"
                        justifyContent="center"
                        fontWeight="600"
                        mt="8px"
                    >
                        <Text>Gerar Saída de Entrega</Text>
                    </Flex>

                    {deliverymens.length <= 0 && (
                        <Flex
                            width={['312px', '360px', '400px']}
                            color="#363636"
                            flexDirection="column"
                            justifyContent="center"
                            mb="16px"
                            border="1px solid"
                            borderColor="#EFEFEF"
                            padding="16px"
                        >
                            <Text fontWeight="500" mb="8px">
                                {
                                    'Atenção voce não tem entregadores cadastrados, acesse o painel e cadastre entregadores no sistema na seção Configurações > Usuários > Entregadores'
                                }
                            </Text>
                        </Flex>
                    )}

                    {deliverymens.length > 0 && (
                        <Flex
                            width={['312px', '360px', '400px']}
                            color="#363636"
                            flexDirection="column"
                            justifyContent="center"
                            mb="16px"
                            border="1px solid"
                            borderColor="#EFEFEF"
                            padding="8px"
                        >
                            <Text fontWeight="500" mb="8px">
                                Selecione o entregador
                            </Text>
                            <Flex flexDirection={'column'} style={{ gap: '8px' }}>
                                {deliverymens.map((item) => (
                                    <Checkbox
                                        key={item.id}
                                        name="deliveryman"
                                        isChecked={deliveryman === item.id}
                                        onClick={() => setDeliveryman(item.id)}
                                        value={item.id}
                                        mr="16px"
                                        size="lg"
                                    >
                                        <Text fontSize="14px">{item.name}</Text>
                                    </Checkbox>
                                ))}
                            </Flex>
                        </Flex>
                    )}

                    {deliverymens.length > 0 && (
                        <Button
                            type="submit"
                            width={['312px', '360px', '400px']}
                            backgroundColor="#F2B502"
                            height="48px"
                            mt="8px"
                            borderRadius="4px"
                            mb="24px"
                        >
                            <Flex fontSize="14px">
                                <Text>Confirmar</Text>
                            </Flex>
                        </Button>
                    )}
                </Flex>
            )}

            {isSucessSubmit && (
                <Flex width="100vw" height="100vh" flexDirection="column">
                    <Flex width="100%" flexDirection="column" alignItems="center">
                        <Flex
                            width="100%"
                            height="72px"
                            backgroundColor="#42478B"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Image src={logo} width="64px" height="40px" />
                        </Flex>
                        <Button
                            mt="48px"
                            type="button"
                            width={['312px', '360px', '400px']}
                            backgroundColor="green.500"
                            height="48px"
                            borderRadius="4px"
                            mb="12px"
                            onClick={() => navigate(0)}
                        >
                            <Flex fontSize="14px">
                                <Text>Confirmar Entrega</Text>
                            </Flex>
                        </Button>

                        <Button
                            type="button"
                            width={['312px', '360px', '400px']}
                            backgroundColor="pink.500"
                            height="48px"
                            mt="8px"
                            borderRadius="4px"
                            mb="24px"
                            onClick={() => (window.location.href = 'https://google.com')}
                            _hover={{
                                backgroundColor: 'pink.500'
                            }}
                        >
                            <Flex fontSize="14px">
                                <Text>Sair</Text>
                            </Flex>
                        </Button>
                    </Flex>
                </Flex>
            )}
        </Form>
    );
};

export default DeliveryIndex;
