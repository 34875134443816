import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiSearch, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Badge, Box, Checkbox, Divider, Flex, PseudoBox, Text, useToast } from '@chakra-ui/core';

import Button from '../../../../components/Button';
import InputSearch from '../../../../components/Form/InputSearch';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const Categories: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { changeTitle } = useLayout();

    const [categories, setCategories] = useState([]);
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

    const inputQueryRef = useRef<HTMLInputElement>(undefined);

    const handleDeleteCategories = useCallback(
        (categoriesIds: string[]) => {
            const selectedCategories = categoriesIds.map((id) =>
                categories.find((category) => category.id === id)
            );

            const defaultCategories = selectedCategories.filter((category) => category.is_default);

            if (defaultCategories.length > 0) {
                toast({
                    title: 'Ocorreu um problema',
                    description: 'Apenas categorias customizadas podem ser removidas',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            } else {
                apiGateway
                    .delete('/categories', { params: { ids: categoriesIds } })
                    .then((response) => {
                        if (response.status === HTTP.STATUS.SUCCESS) {
                            toast({
                                title: 'Categoria removida',
                                description: '',
                                status: 'error',
                                duration: 4000,
                                isClosable: true
                            });

                            navigate(0);
                        }
                    });
            }
        },
        [categories]
    );

    const handleChangeSelectedCategoriesIds = useCallback(
        (input: EventTarget & HTMLInputElement) => {
            let list = selectedCategoriesIds;

            if (input.checked) {
                if (!selectedCategoriesIds.includes(input.value)) {
                    list.push(input.value);
                }
            } else {
                list = list.filter((item) => item !== input.value);
            }

            setSelectedCategoriesIds(list);
        },
        [selectedCategoriesIds]
    );

    const handleSearchCategories = useCallback(() => {
        const query = inputQueryRef?.current?.value;
        apiGateway.get(`/categories?query=${query}`).then((response) => {
            setCategories(response.data);
        });
    }, []);

    const handleEdit = useCallback(
        (id: string) => {
            navigate(`/categories/edit/${id}`);
        },
        [navigate]
    );

    const CategoryRow = ({ style, index, data }: any) => {
        return (
            <ListRow style={style} index={index}>
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={(e) => handleChangeSelectedCategoriesIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Text textAlign="center" lineHeight="16px">
                        {data[index].name}
                    </Text>
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Badge
                        width="100%"
                        maxWidth="80px"
                        textAlign="center"
                        backgroundColor={data[index].show_in_header ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                    >
                        {data[index].show_in_header ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Badge
                        width="100%"
                        maxWidth="80px"
                        textAlign="center"
                        backgroundColor={data[index].show_in_footer ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                    >
                        {data[index].show_in_footer ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Badge
                        width="100%"
                        maxWidth="100px"
                        textAlign="center"
                        backgroundColor={data[index].is_default ? 'green.500' : 'blue.500'}
                        color="white"
                        py="4px"
                    >
                        {data[index].is_default ? 'Padrão' : 'Customizada'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text textAlign="center" lineHeight="16px">
                        {data[index].parent?.name}
                    </Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        title="Visualizar"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEdit(data[index].id)}
                    >
                        <FiEdit2 size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Categorias');
        apiGateway.get('/categories').then((response) => {
            setCategories(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/categories/register')}
                >
                    Nova Categoria
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Categorias"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteCategories(selectedCategoriesIds)}
                >
                    <FiTrash2 size={20} />
                </Button>

                <Box ml="auto" width="100%">
                    <InputSearch
                        ref={inputQueryRef}
                        name="query"
                        placeholder="Pesquisar por nome da categoria"
                        onEnterPress={() => handleSearchCategories()}
                    />
                </Box>

                <Button
                    backgroundColor="green.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    title="Pesquisar Categorias"
                    _hover={{
                        backgroundColor: 'purple.500'
                    }}
                    onClick={() => handleSearchCategories()}
                >
                    <FiSearch size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column" backgroundColor="white" px="24px">
                    <Flex width="100%" py="8px" justifyContent="space-between" flexWrap="wrap">
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Categoria</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Mostrar no Cabeçalho</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Mostrar no Rodapé</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Tipo</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Categoria Pai</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {categories.length > 0 && <List item={categories} row={CategoryRow} />}

                    {categories.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Categories;
