import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Heading, Image, useToast } from '@chakra-ui/core';
import { Form } from '@unform/web';

import { FormHandles } from '@unform/core';
import logo from '../../../assets/logo-white.png';
import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import Loader from '../../../shared/components/Loader';
import { HTTP } from '../../../shared/constants';
import apiGateway from '../../../shared/services/apiGateway';

type DataSubmit = {
    name: string;
    email: string;
    password: string;
};

const StoreSignIn: React.FC = () => {
    const navigate = useNavigate();

    const formRef = useRef<FormHandles>(undefined);
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    const toast = useToast();

    const handleAuth = useCallback(() => {
        const email = formRef.current.getFieldValue('email_admin');
        const password = formRef.current.getFieldValue('password_admin');
        if (
            email === process.env.REACT_APP_EMAIL_ADMIN &&
            password === process.env.REACT_APP_PASSWORD_ADMIN
        ) {
            setAuthenticated(true);
        } else {
            toast({
                title: 'Login Inválido.',
                description: 'Tente novamente.',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
        }
    }, []);

    const handleSubmitLogin = useCallback(
        async (dataSubmit: DataSubmit) => {
            setLoading(true);

            try {
                const { name, email, password } = dataSubmit;

                const formData = {
                    store: {
                        name: name
                    },
                    user: {
                        name: 'ADMIN',
                        email: email,
                        password: password
                    }
                };

                const response = await apiGateway.post('/stores', formData);

                if (
                    response.status === HTTP.STATUS.CREATED ||
                    response.status === HTTP.STATUS.SUCCESS
                ) {
                    navigate('/stores/success');
                } else {
                    console.log(response?.data);
                    toast({
                        title: 'Não foi possível criar a loja.',
                        description: 'Tente novamente mais tarde.',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.log(err);
                toast({
                    title: 'Não foi possível criar a loja.',
                    description: 'Tente novamente mais tarde.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }
        },
        [navigate]
    );

    return (
        <Flex width="100%" height="100vh">
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="purple.500"
                height="100%"
            >
                <Box px="160px">
                    <Image src={logo} alt="Logo E-Florista" />
                </Box>
            </Flex>
            <Flex width="50%" justifyContent="center" alignItems="center" height="100%">
                {!loading && (
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmitLogin}
                        style={{
                            width: '100%'
                        }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        {!authenticated && (
                            <Flex
                                width="100%"
                                flexDirection="column"
                                p="98px"
                                fontWeight="500"
                                color="purple.500"
                            >
                                <Heading fontSize="24px" textAlign="center" mb="4">
                                    Fazer Login de Admin
                                </Heading>

                                <Input name="email_admin" size="md" placeholder="E-mail" />
                                <Input
                                    name="password_admin"
                                    typeInput="password"
                                    size="md"
                                    placeholder="Senha"
                                />

                                <Flex justifyContent="space-between">
                                    <Flex width="100%" justifyContent="center">
                                        <Button
                                            type="button"
                                            width="100%"
                                            maxWidth="200px"
                                            fontSize="16px"
                                            rounded="6px"
                                            py="8px"
                                            backgroundColor="purple.500"
                                            _hover={{
                                                backgroundColor: 'green.500'
                                            }}
                                            onClick={() => handleAuth()}
                                        >
                                            Fazer Login
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                        {authenticated && (
                            <Flex
                                width="100%"
                                flexDirection="column"
                                p="98px"
                                fontWeight="500"
                                color="purple.500"
                            >
                                <Heading fontSize="24px" textAlign="center" mb="4">
                                    Cadastre uma Nova Loja
                                </Heading>

                                <Input name="name" size="md" placeholder="Nome da Loja" />
                                <Input name="email" size="md" placeholder="E-mail" />
                                <Input
                                    name="password"
                                    typeInput="password"
                                    size="md"
                                    placeholder="Senha"
                                />

                                <Flex justifyContent="space-between">
                                    <Flex width="100%" justifyContent="center">
                                        <Button
                                            type="button"
                                            width="100%"
                                            maxWidth="200px"
                                            fontSize="16px"
                                            rounded="6px"
                                            py="8px"
                                            backgroundColor="purple.500"
                                            _hover={{
                                                backgroundColor: 'green.500'
                                            }}
                                            onClick={() => formRef.current.submitForm()}
                                        >
                                            Cadastrar Loja
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                    </Form>
                )}

                {loading && (
                    <Flex width="100%" height="100%" position="relative" flexDirection="column">
                        <Loader text="Estamos criando sua loja, aguarde..." />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default StoreSignIn;
