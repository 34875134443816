import React from 'react';
import Lottie from 'react-lottie';

import { Flex, Text } from '@chakra-ui/core';
import animationData from '../../assets/lotties/loader.json';

export default function Spinner() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Flex flexDirection="column" alignItems="center" style={{ gap: 4 }}>
            <Lottie
                isClickToPauseDisabled
                options={defaultOptions}
                height={54}
                style={{
                    maxWidth: 54
                }}
            />

            <Text>Carregando...</Text>
        </Flex>
    );
}
