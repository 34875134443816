import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/TextArea';
import TextEditor from '../../../components/Form/TextEditor';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';

const PagesRegister: React.FC = () => {
    const formRef = useRef<FormHandles>(undefined);
    const [isEnable, setEnable] = useState(false);
    const [showInHeader, setShowInHeader] = useState('false');
    const toast = useToast();
    const navigate = useNavigate();
    const handleChangeTitle = useCallback(() => {
        const title = formRef.current.getFieldValue('title');
        formRef.current.getFieldRef('slug').value = title;
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.post('/pages', {
                    title: formData.title,
                    slug: formData.slug,
                    content: formData.content,
                    meta_description: formData.meta_description,
                    is_enable: isEnable,
                    show_in_header: showInHeader
                });

                if (response.status === HTTP.STATUS.CREATED) {
                    FormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Página cadastrada com sucesso',
                        toast
                    );

                    navigate('/settings/pages');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [navigate, isEnable, showInHeader, toast]
    );

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Informações Básicas
                </Text>
                <Flex minWidth="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                    <Box width="32%">
                        <Input
                            name="title"
                            label="Título"
                            isRequired
                            size="sm"
                            onChange={() => handleChangeTitle()}
                        />
                    </Box>
                    <Box width="32%">
                        <Input name="slug" label="Slug" isRequired size="sm" />
                    </Box>

                    <Flex width="32%" mb="16px">
                        <Box fontWeight="500" mr="48px">
                            <Text>Ativar página?</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable === false}
                                    onChange={() => setEnable(!isEnable)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>

                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable}
                                    onChange={() => setEnable(!isEnable)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex width="32%" mb="16px">
                        <Box fontWeight="500" mr="48px">
                            <Text>Mostrar no cabeçalho?</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={showInHeader !== 'true'}
                                    onChange={() =>
                                        setShowInHeader(showInHeader === 'true' ? 'false' : 'true')
                                    }
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>

                                <Checkbox
                                    name="is_enable"
                                    isChecked={showInHeader === 'true'}
                                    onChange={() =>
                                        setShowInHeader(showInHeader === 'true' ? 'false' : 'true')
                                    }
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>

                <Box width="100%" mb="16px">
                    <TextArea
                        name="meta_description"
                        label="Meta Description"
                        isRequired
                        size="sm"
                        mb="4px"
                    />
                </Box>

                <Box width="100%">
                    <TextEditor
                        name="content"
                        label="Conteúdo"
                        isRequired
                        size="sm"
                        mb="4px"
                        height={400}
                    />
                </Box>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default PagesRegister;
