/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';

import { Input, InputProps } from '@chakra-ui/core';

interface IProps extends InputProps {
    onEnterPress?: () => void;
}

const InputSearch: React.FC<IProps> = React.forwardRef(
    ({ onEnterPress = undefined, ...rest }, ref) => (
        <Input
            ref={ref}
            borderRadius="0px"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="false"
            onKeyDown={(e) => {
                if (e.key === 'Enter' && onEnterPress) {
                    onEnterPress();
                }
            }}
            _focus={{
                outline: 'none'
            }}
            {...(rest as any)}
        />
    )
);

export default InputSearch;
