import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Select from '../../../components/Form/Select';
import TextArea from '../../../components/Form/TextArea';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';

const CitiesRegister: React.FC = () => {
    const formRef = useRef<FormHandles>(undefined);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const navigate = useNavigate();

    const toast = useToast();

    const handleChangeState = useCallback((stateOption: any) => {
        const stateValue = stateOption.value;

        if (stateValue) {
            apiGateway.get(`/location/states/${stateValue}/cities`).then((response) => {
                setCities(response.data);
            });
        }
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.post('/cities', {
                    name: formData.name,
                    description: formData.description,
                    meta_description: formData.meta_description,
                    keywords: formData.keywords,
                    state_id: formData.state_id
                });

                if (response.status === HTTP.STATUS.CREATED) {
                    FormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Cidade cadastrada com sucesso',
                        toast
                    );

                    FormHelper.reset(formRef as any);

                    navigate('/settings/cities');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [history, toast]
    );

    useEffect(() => {
        apiGateway.get('/location/states').then((response) => {
            setStates(response.data);
        });
    }, []);

    return (
        <Form
            ref={formRef as any}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Informações Básicas
                </Text>
                <Flex minWidth="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                    <Box width="48%">
                        <Select
                            name="state_id"
                            label="Selecione o estado"
                            isRequired
                            size="sm"
                            onChange={(e) => handleChangeState(e.currentTarget)}
                        >
                            <option value="">Selecione um estado</option>
                            {states.map((state: any) => (
                                <option key={state.id} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </Select>
                    </Box>

                    <Box width="48%">
                        <Select name="name" label="Selecione o a cidade" isRequired size="sm">
                            <option value="">Selecione uma cidade</option>
                            {cities.map((city: any) => (
                                <option key={city.id} value={city.name}>
                                    {city.name}
                                </option>
                            ))}
                        </Select>
                    </Box>
                </Flex>

                <Flex width="100%" flexDirection="column">
                    <Box>
                        <TextArea name="description" label="Descriçao" />
                    </Box>

                    <Box>
                        <TextArea name="meta_description" label="Meta Description" />
                    </Box>

                    <Box>
                        <TextArea name="keywords" label="Keywords" />
                    </Box>
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default CitiesRegister;
