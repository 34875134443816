import { Button, Collapse, Flex, Text } from '@chakra-ui/core';
import { format, subMonths } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import { FiCalendar, FiFile, FiSearch } from 'react-icons/fi';
import Form from '../../../components/Form';
import Calendar from '../../../components/Form/Calendar';
import InputSearch from '../../../components/Form/InputSearch';
import Select from '../../../components/Form/Select';
import Spinner from '../../../components/Spinner';
import { useLayout } from '../../../layouts/default';
import { HTTP } from '../../../shared/constants';
import { useAuth } from '../../../shared/hooks/useAuth';
import apiGateway from '../../../shared/services/apiGateway';
import CurrencyUtils from '../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../shared/utils/DateUtils';
import TextUtils from '../../../shared/utils/TextUtils';

const DeliveryRelatoryPage: React.FC = () => {
    const { changeTitle } = useLayout();
    const formRef = useRef(undefined);
    const { store } = useAuth();

    const [showCalendar, setShowCalendar] = useState(false);
    const [periodDate1Filter, setPeriodDate1Filter] = useState(subMonths(new Date(), 6));
    const [periodDate2Filter, setPeriodDate2Filter] = useState(new Date());
    const [statusFilter, setStatusFilter] = useState(null);
    const [codeFilter, setCodeFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [orders, setOrders] = useState([]);

    const totalDeliveryValue = useMemo(() => {
        return orders.reduce((prev, curr) => {
            return Number(prev) + Number(curr.delivery_value);
        }, 0);
    }, [orders]);

    const exportLink = useMemo(() => {
        let baseUrl = `${process.env.REACT_APP_API_URL}/relatories/delivery/export/excel/${store}?`;
        baseUrl += `date1=${new Date(format(periodDate1Filter, 'yyyy-MM-dd')).toISOString()}`;
        baseUrl += `&date2=${new Date(format(periodDate2Filter, 'yyyy-MM-dd')).toISOString()}`;

        baseUrl += statusFilter ? `&status=${statusFilter}` : '';
        baseUrl += codeFilter ? `&code=${codeFilter}` : '';

        return baseUrl;
    }, [store, periodDate1Filter, periodDate2Filter, statusFilter, codeFilter]);

    const handleChangePeriodDate1Filter = useCallback((date: Date, modifiers: DayModifiers) => {
        setCodeFilter('');
        if (modifiers.available && !modifiers.disabled) {
            setPeriodDate1Filter(date);
        }
    }, []);

    const handleChangePeriodDate2Filter = useCallback((date: Date, modifiers: DayModifiers) => {
        setCodeFilter('');
        if (modifiers.available && !modifiers.disabled) {
            setPeriodDate2Filter(date);
        }
    }, []);

    const handleSearch = useCallback(async () => {
        const response = await apiGateway.get('/relatories/products', {
            params: {
                date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd')),
                status: statusFilter,
                code: codeFilter
            }
        });

        if (response.status === HTTP.STATUS.SUCCESS) setOrders(response.data);

        setShowCalendar(false);
    }, [periodDate1Filter, periodDate2Filter, statusFilter]);

    useEffect(() => {
        changeTitle('Relatório de Entregas');

        setIsLoading(true);
        apiGateway
            .get('/relatories/delivery_report', {
                params: {
                    date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                    date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd')),
                    status: statusFilter,
                    code: codeFilter
                }
            })
            .then(({ data, status }) => {
                if (status === 200) {
                    setOrders(data);
                }

                setIsLoading(false);
            });
    }, [periodDate1Filter, periodDate2Filter, statusFilter, codeFilter]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSearch}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                p="16px"
                style={{ gap: '8px' }}
            >
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex>
                        <Text>{`Quantidade de entrega no período: ${orders.length}`}</Text>
                    </Flex>
                    <Flex alignItems="center" style={{ gap: '8px' }}>
                        <Text fontWeight="400">Data:</Text>
                        <Flex flexDirection="column" mr="16px">
                            <Button
                                color="white"
                                width="144px"
                                backgroundColor="purple.500"
                                borderRadius="2px"
                                size="sm"
                                height="32px"
                                py="0px"
                                fontSize="12px"
                                fontWeight="500"
                                display="flex"
                                alignItems="center"
                                _focus={{
                                    outline: 'none'
                                }}
                                _hover={{
                                    backgroundColor: 'purple.500'
                                }}
                                onClick={() => setShowCalendar((oldState) => !oldState)}
                            >
                                <FiCalendar size={16} />
                                <Text ml="8px">
                                    {`De ${format(periodDate1Filter, 'dd/MM/yyyy')}`}
                                </Text>
                            </Button>

                            <Collapse width="100%" isOpen={showCalendar} display="flex">
                                <Calendar
                                    isErrored={false}
                                    selectedDate={periodDate1Filter}
                                    handleDateChange={handleChangePeriodDate1Filter}
                                    handleMonthChange={undefined}
                                />
                            </Collapse>
                        </Flex>

                        <Flex flexDirection="column">
                            <Button
                                color="white"
                                width="144px"
                                backgroundColor="purple.500"
                                borderRadius="2px"
                                size="sm"
                                height="32px"
                                py="0px"
                                fontSize="12px"
                                fontWeight="500"
                                display="flex"
                                alignItems="center"
                                _focus={{
                                    outline: 'none'
                                }}
                                _hover={{
                                    backgroundColor: 'purple.500'
                                }}
                                onClick={() => setShowCalendar((oldState) => !oldState)}
                            >
                                <FiCalendar size={16} />
                                <Text ml="8px">
                                    {`Até ${format(periodDate2Filter, 'dd/MM/yyyy')}`}
                                </Text>
                            </Button>

                            <Collapse width="100%" isOpen={showCalendar} display="flex">
                                <Calendar
                                    isErrored={false}
                                    selectedDate={periodDate2Filter}
                                    handleDateChange={handleChangePeriodDate2Filter}
                                    handleMonthChange={undefined}
                                />
                            </Collapse>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center" style={{ gap: '8px' }}>
                        <Text whiteSpace="nowrap" fontWeight="400">
                            Status:
                        </Text>
                        <Select
                            name="status"
                            mb="0px"
                            size="sm"
                            onChange={(e) => {
                                setStatusFilter(
                                    e.currentTarget.value === 'Todos' ? null : e.currentTarget.value
                                );
                            }}
                            defaultValue="Todos"
                        >
                            <option>Todos</option>
                            <option value="ENTREGUE">Entregue</option>
                            <option value="SAIU_PARA_ENTREGA">Saiu Para Entrega</option>
                            <option value="EM_PRODUCAO">Em Produção</option>
                            <option value="AGUARDANDO_PRODUCAO">Aguardando Produção</option>
                        </Select>
                    </Flex>

                    <Flex width="50%" style={{ gap: '16px' }} alignItems="center">
                        <Flex flex="1" position="relative">
                            <InputSearch
                                placeholder="Pedido"
                                size="sm"
                                onChange={(e) => setCodeFilter(e.currentTarget.value)}
                                value={codeFilter}
                            />
                            <Flex position="absolute" top="6px" right="8px">
                                <FiSearch size={18} />
                            </Flex>
                        </Flex>

                        <Flex>
                            <Button
                                fontWeight="500"
                                style={{ gap: '4px' }}
                                _focus={{ outline: 'none' }}
                                _hover={{ backgroundColor: 'transparent' }}
                                flexDirection="column"
                                backgroundColor="transparent"
                            >
                                <a
                                    href={exportLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FiFile />
                                    <Text fontSize="14px" fontWeight="500">
                                        exportar
                                    </Text>
                                </a>
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex width="100%" flexDirection="column" mt="16px">
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        backgroundColor="gray.500"
                        fontSize="14px"
                        px="4px"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="48px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="88px">
                            <Text whiteSpace="nowrap">Data de Entrega</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="54px">
                            <Text whiteSpace="nowrap">Horário</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text whiteSpace="nowrap">Entregador</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text whiteSpace="nowrap">Cidade</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="54px">
                            <Text whiteSpace="nowrap">Valor Cobrado</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="144px">
                            <Text whiteSpace="nowrap">Status</Text>
                        </Flex>
                    </Flex>
                    <Flex
                        width="100%"
                        flexDirection="column"
                        fontSize="14px"
                        maxHeight="70vh"
                        overflowX="hidden"
                        overflowY="auto"
                    >
                        {isLoading && (
                            <Flex
                                width="100%"
                                height="70vh"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Spinner />
                            </Flex>
                        )}
                        {!isLoading && orders.length === 0 && (
                            <Flex width="100%" py="4px">
                                <Text>Nenhum resultado encontrado.</Text>
                            </Flex>
                        )}
                        {!isLoading &&
                            orders.map((order) => (
                                <Flex
                                    key={order.id}
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    py="2px"
                                >
                                    <Flex width="100%" justifyContent="center" maxWidth="48px">
                                        <Text fontSize="14px">{order.code}</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="88px">
                                        <Text fontSize="14px">{`${DateUtils.formatStringToDate(
                                            order.delivery_date
                                        )}`}</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="54px">
                                        <Text fontSize="14px">{order.delivery_hour}</Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="96px" justifyContent="center">
                                        <Text fontSize="14px" whiteSpace="nowrap">
                                            {order?.deliveryman?.name || 'Não Informado'}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="96px" justifyContent="center">
                                        <Text fontSize="14px" whiteSpace="nowrap">
                                            {order?.delivery_address?.city || 'Retirada Loja'}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="54px" justifyContent="center">
                                        <Text fontSize="14px">
                                            {CurrencyUtils.numberToCurrency(order.delivery_value)}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" maxWidth="144px" justifyContent="center">
                                        <Text fontSize="14px" whiteSpace="nowrap">
                                            {TextUtils.convertDeliveryStatus(order.delivery_status)}
                                        </Text>
                                    </Flex>
                                </Flex>
                            ))}
                    </Flex>
                    <Flex width="100%" justifyContent="space-between" backgroundColor="gray.700">
                        <Flex width="100%" justifyContent="center" maxWidth="48px"></Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="88px">
                            <Text whiteSpace="nowrap"></Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="54px">
                            <Text whiteSpace="nowrap"></Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text whiteSpace="nowrap"></Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text whiteSpace="nowrap"></Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="54px">
                            <Text whiteSpace="nowrap" color="white">
                                {CurrencyUtils.numberToCurrency(String(totalDeliveryValue))}
                            </Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="144px">
                            <Text whiteSpace="nowrap"></Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default DeliveryRelatoryPage;
