/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import { FiCalendar, FiMinus, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Collapse, Divider, Flex, Image, PseudoBox, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Calendar from '../../../../components/Form/Calendar';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import InputCurrency from '../../../../components/Form/InputCurrency';
import Select from '../../../../components/Form/Select';
import TextArea from '../../../../components/Form/TextArea';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import NumberUtils from '../../../../shared/utils/NumberUtils';
import ProductsResults from '../../components/ProductsResults';

interface IOrderCart {
    products: any[];
    delivery_value: number;
    products_value: number;
    total_value: number;
    tax_value: number;
    discount_value: number;
}

const OrdersEditPage: React.FC = () => {
    const [identifySender, setIdentifySender] = useState(false);
    const navigate = useNavigate();

    const { changeTitle } = useLayout();

    const [retireStore, setRetireStore] = useState(false);

    const { id } = useParams();

    const [order, setOrder] = useState(null);
    const [orderCart, setOrderCart] = useState<IOrderCart>({
        products: [],
        delivery_value: 0,
        products_value: 0,
        total_value: 0,
        tax_value: 0,
        discount_value: 0
    });

    const handleAddDescount = useCallback((value) => {
        setOrderCart((oldState) => {
            const { products_value, delivery_value, tax_value } = oldState;
            const discount_value = Number(NumberUtils.formatNumber(value));
            return {
                ...oldState,
                discount_value,
                total_value: products_value + delivery_value + tax_value - discount_value
            };
        });
    }, []);

    const handleAddTax = useCallback((value) => {
        setOrderCart((oldState) => {
            const { products_value, delivery_value, discount_value } = oldState;
            const tax_value = Number(NumberUtils.formatNumber(value));
            return {
                ...oldState,
                tax_value,
                total_value: products_value + delivery_value - discount_value + tax_value
            };
        });
    }, []);

    const formRef = useRef<FormHandles>(undefined);
    const toast = useToast();

    const [colappseBoxProducts, setCollapseBoxProducts] = useState(false);

    const [productsResults, setProductsResults] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState('CREDITCARD');

    const [deliveryCities, setDeliveryCities] = useState([]);
    const [deliveryDistricts, setDeliveryDistricts] = useState([]);

    const [selectedDeliveryCity, setSelectedDeliveryCity] = useState(null);

    const [showCalendar, setShowCalendar] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(new Date());

    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [cityId, setCityId] = useState('');
    const [neighborhoodId, setNeighborhoodId] = useState('');

    const handleChangeDeliveryDate = useCallback((date: Date, modifiers: DayModifiers) => {
        if (modifiers.available && !modifiers.disabled) {
            setDeliveryDate(date);
            setShowCalendar(false);
        }
    }, []);

    const handleChangeProductSearch = useCallback(async (query) => {
        const response = await apiGateway.get(`/products?query=${query}`);

        setProductsResults(response.data);
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                if (
                    !retireStore &&
                    paymentMethod !== 'PAY_IN_STORE' &&
                    paymentMethod !== 'PAY_IN_DELIVERY'
                ) {
                    if (!formData.city || !formData.neighborhood || !formData.street) {
                        toast({
                            title: 'Dados Imcompleto',
                            description: 'Endereço de entrega é obrigatório',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });
                        return;
                    }
                }

                const postData = {
                    id: order.id,
                    tax_value: NumberUtils.formatNumber(formData.tax_value),
                    discount_value: NumberUtils.formatNumber(formData.discount_value),
                    buyer: {
                        id: order?.buyer?.id,
                        name: formData.buyer_name,
                        document: formData.buyer_document,
                        telephone: formData.buyer_telephone,
                        email: formData.buyer_email
                    },
                    delivery_address: {
                        id: order?.delivery_address?.id,
                        zipcode: '00000000',
                        city: formData.city,
                        city_id: cityId,
                        neighborhood: formData.neighborhood,
                        neighborhood_id: neighborhoodId,
                        street: formData.street,
                        number: formData.number,
                        complement: formData.complement,
                        state: 'RS',
                        country: 'BR'
                    },
                    order_details: {
                        destinatary_name: formData.destinatary_name,
                        destinatary_telephone: formData.destinatary_telephone,
                        card_message: formData.card_message,
                        identify_sender: identifySender,
                        observations: formData.observations,
                        identify_name: formData.identify_name
                    },
                    delivery_date: format(deliveryDate, 'dd/MM/yyyy'),
                    delivery_hour: formData.delivery_hour,
                    payment_method_type: paymentMethod,
                    order_products: orderCart.products.map((item) => {
                        return {
                            product_id: item.id,
                            quantity: item.quantity
                        };
                    })
                };

                await apiGateway.put(`/orders/${id}`, postData).then((response) => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        toast({
                            title: 'Registro Atualizado',
                            description: 'Venda atualizada com sucesso',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });

                        navigate('/orders');
                    }
                });
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [
            id,
            deliveryDate,
            navigate,
            identifySender,
            cityId,
            neighborhoodId,
            orderCart.products,
            paymentMethod,
            toast,
            retireStore
        ]
    );

    useEffect(() => {
        apiGateway.get(`/orders/${id}`).then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                const orderData = response.data;
                changeTitle(`Pedido - ${response.data.code}`);

                setOrder(orderData);
                setIdentifySender(orderData.details.identify_sender);

                setDeliveryDate(parseISO(orderData.delivery_date));
                setPaymentMethod(orderData.payment_method.type);
                setOrderCart({
                    products: orderData.products.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            quantity: item.quantity,
                            value: item.price_sale,
                            thumb_url: item.cover.thumb_url
                        };
                    }),
                    delivery_value: Number(orderData.delivery_value),
                    products_value: Number(orderData.products_value),
                    tax_value: Number(orderData.tax_value),
                    discount_value: Number(orderData.discount_value),
                    total_value: Number(orderData.total_value)
                });

                setRetireStore(orderData.delivery_status === 'RETIRADA_NA_LOJA');

                apiGateway.get('/settings/delivery_cities/enabled').then((responseData) => {
                    if (responseData.status === HTTP.STATUS.SUCCESS) {
                        setDeliveryCities(responseData.data);

                        const selectedCity = responseData.data.find(
                            (item) => item.name === orderData.delivery_address.city
                        );
                        setSelectedDeliveryCity(selectedCity);
                        if (selectedCity) setCityId(selectedCity.id);
                        apiGateway.get('/settings/delivery_methods').then((responseData2) => {
                            if (responseData2.status === HTTP.STATUS.SUCCESS) {
                                const deliveryMethodEnabled = responseData2.data.find(
                                    (item) => item.is_enable === true
                                );

                                if (deliveryMethodEnabled) {
                                    setDeliveryMethod(deliveryMethodEnabled);

                                    if (deliveryMethodEnabled.type === 'DISTRICT') {
                                        setDeliveryDistricts(selectedCity.neighborhoods);

                                        const neighborhood = selectedCity.neighborhoods.find(
                                            (item) =>
                                                String(item.name).trim() ===
                                                String(
                                                    orderData.delivery_address.neighborhood
                                                ).trim()
                                        );

                                        setNeighborhoodId(neighborhood.id);
                                    }
                                }
                            }
                        });
                    }
                });
            }
        });
    }, [changeTitle, id]);

    const handleUpdateValues = useCallback(
        (updatedProducts): Omit<IOrderCart, 'products'> => {
            const { delivery_value, tax_value, discount_value } = orderCart;

            const totalProducts = updatedProducts.reduce(
                (accumulator: number, currentValue: any) => {
                    return (accumulator += currentValue.value * currentValue.quantity);
                },
                0
            );

            return {
                delivery_value,
                products_value: totalProducts,
                tax_value,
                discount_value,
                total_value: totalProducts + delivery_value + tax_value - discount_value
            };
        },
        [orderCart]
    );

    const handleRemoveProduct = useCallback(
        (product) => {
            const { products } = orderCart;

            const updatedProducts = products.filter((item) => item.id !== product.id);

            setOrderCart((oldState) => {
                return {
                    ...oldState,
                    products: updatedProducts,
                    ...handleUpdateValues(updatedProducts)
                };
            });
        },
        [handleUpdateValues, orderCart]
    );

    const handleChangeQuantity = useCallback(
        (product: any, type = 'ADD') => {
            const { products } = orderCart;
            let newQuantity = product.quantity;

            const updatedProducts = products.map((item) => {
                if (item.id === product.id) {
                    if (type === 'ADD') {
                        newQuantity += 1;
                    } else {
                        newQuantity -= 1;
                    }

                    return {
                        ...product,
                        quantity: newQuantity
                    };
                }

                return item;
            });

            if (newQuantity !== 0) {
                setOrderCart((oldState) => {
                    return {
                        ...oldState,
                        products: updatedProducts,
                        ...handleUpdateValues(updatedProducts)
                    };
                });
            } else {
                handleRemoveProduct(product);
            }
        },
        [handleRemoveProduct, handleUpdateValues, orderCart]
    );

    const handleAddProduct = useCallback(
        (product) => {
            const { products } = orderCart;

            const productExistInCart = products.find((item) => item.id === product.id);

            if (!productExistInCart) {
                products.push({
                    id: product.id,
                    name: product.name,
                    quantity: 1,
                    value: product.price_sale,
                    thumb_url: product.cover.thumb_url
                });

                setOrderCart((oldState) => {
                    return {
                        ...oldState,
                        products,
                        ...handleUpdateValues(products)
                    };
                });

                formRef.current.getFieldRef('search').value = '';

                setCollapseBoxProducts(false);
                setProductsResults([]);
            } else {
                toast({});
            }
        },
        [handleUpdateValues, orderCart, toast]
    );

    const handleChangeDeliveryValue = useCallback(() => {
        let deliveryValue = 0;
        if (deliveryMethod.type === 'CITY') {
            deliveryValue = selectedDeliveryCity.price;

            const cityName = formRef.current.getFieldValue('city');

            const selectedCity = deliveryCities.find((item) => item.name === cityName);

            deliveryValue = selectedCity.price;
        } else {
            const districtName = formRef.current.getFieldValue('neighborhood');

            const selectedDistrict = deliveryDistricts.find((item) => item.name === districtName);

            if (selectedDistrict) {
                deliveryValue = selectedDistrict.price;
            }

            console.log(selectedDistrict);
        }

        setOrderCart((oldState) => {
            return {
                ...oldState,
                delivery_value: Number(deliveryValue),
                total_value:
                    oldState.products_value +
                    Number(deliveryValue) +
                    oldState.tax_value -
                    oldState.discount_value
            };
        });
    }, [deliveryCities, deliveryDistricts, deliveryMethod, selectedDeliveryCity]);

    const handleChangeDeliveryCity = useCallback(() => {
        const cityName = formRef.current.getFieldValue('city');

        const selectedCity = deliveryCities.find((item) => item.name === cityName);

        setSelectedDeliveryCity(selectedCity);

        if (deliveryMethod?.type === 'DISTRICT') {
            apiGateway
                .get(`/settings/delivery_cities/${selectedCity?.id}/delivery_districts`)
                .then((response) => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        setDeliveryDistricts(response.data);
                    }
                });
        }

        handleChangeDeliveryValue();
    }, [deliveryCities, deliveryMethod, handleChangeDeliveryValue]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            {order != null && (
                <Flex
                    width="100%"
                    maxWidth="800px"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontSize="14px"
                >
                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="48%" flexDirection="column">
                            <Text color="purple.500" fontSize="20px" mt="16px">
                                Comprador
                            </Text>

                            <Flex
                                minWidth="100%"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                mt="8px"
                            >
                                <Box width="100%">
                                    <Input
                                        name="buyer_name"
                                        label="Nome"
                                        size="sm"
                                        defaultValue={order.buyer.name}
                                    />
                                </Box>

                                <Box width="100%">
                                    <Input
                                        name="buyer_telephone"
                                        label="Telefone"
                                        size="sm"
                                        defaultValue={order.buyer.telephone}
                                    />
                                </Box>

                                <Box width="100%">
                                    <Input
                                        name="buyer_document"
                                        label="CPF"
                                        size="sm"
                                        defaultValue={order.buyer.document}
                                    />
                                </Box>

                                <Box width="100%">
                                    <Input
                                        name="buyer_email"
                                        label="Email"
                                        size="sm"
                                        defaultValue={order.buyer.email}
                                    />
                                </Box>
                            </Flex>
                        </Flex>

                        <Flex width="48%" flexDirection="column">
                            <Text color="purple.500" fontSize="20px" mt="16px">
                                Mensagem no Cartão
                            </Text>

                            <Flex width="100%" mt="32px">
                                <TextArea
                                    name="card_message"
                                    mb="0px"
                                    height="100%"
                                    defaultValue={order.details.card_message}
                                />
                            </Flex>

                            <Box fontWeight="500" mr="48px" mt="16px">
                                <Text>Identificar Remetente?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="show_in_header"
                                        isChecked={identifySender === false}
                                        onChange={() => setIdentifySender(!identifySender)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>

                                    <Checkbox
                                        name="show_in_header"
                                        isChecked={identifySender}
                                        onChange={() => setIdentifySender(!identifySender)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                                {identifySender && (
                                    <Box width="100%" mt="16px">
                                        <Input
                                            name="identify_name"
                                            label="Nome de Identificação"
                                            size="sm"
                                            defaultValue={order.details.identify_name}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="space-between">
                        {retireStore && (
                            <Flex width="48%" flexDirection="column">
                                <Box fontWeight="500" mr="48px" mt="16px">
                                    <Text>Retirada na Loja</Text>
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                        maxWidth="80px"
                                        alignItems="flex-end"
                                        mt="8px"
                                    >
                                        <Checkbox
                                            name="retire_store"
                                            isChecked={!retireStore}
                                            onChange={() => setRetireStore(!retireStore)}
                                            value="0"
                                            mr="16px"
                                        >
                                            <Text fontSize="14px">Não</Text>
                                        </Checkbox>

                                        <Checkbox
                                            name="retire_store"
                                            isChecked={retireStore}
                                            onChange={() => setIdentifySender(!identifySender)}
                                            value="1"
                                            mr="16px"
                                        >
                                            <Text fontSize="14px">Sim</Text>
                                        </Checkbox>
                                    </Flex>
                                </Box>
                            </Flex>
                        )}
                        {!retireStore && (
                            <Flex width="48%" flexDirection="column">
                                <Text color="purple.500" fontSize="20px" mt="16px">
                                    Endereço de Entrega
                                </Text>

                                <Box fontWeight="500" mr="48px" mt="16px">
                                    <Text>Retirada na Loja</Text>
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                        maxWidth="80px"
                                        alignItems="flex-end"
                                        mt="8px"
                                    >
                                        <Checkbox
                                            name="retire_store"
                                            isChecked={!retireStore}
                                            onChange={() => setRetireStore(!retireStore)}
                                            value="0"
                                            mr="16px"
                                        >
                                            <Text fontSize="14px">Não</Text>
                                        </Checkbox>

                                        <Checkbox
                                            name="retire_store"
                                            isChecked={retireStore}
                                            onChange={() => setRetireStore(!retireStore)}
                                            value="1"
                                            mr="16px"
                                        >
                                            <Text fontSize="14px">Sim</Text>
                                        </Checkbox>
                                    </Flex>
                                </Box>

                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    mt="8px"
                                >
                                    <Box width="100%">
                                        <Select
                                            label="Cidade"
                                            name="city"
                                            size="sm"
                                            isRequired={paymentMethod !== 'PAY_IN_STORE'}
                                            onChange={(e) => {
                                                handleChangeDeliveryCity();
                                                setCityId(
                                                    e.target[e.target.selectedIndex].getAttribute(
                                                        'data-id'
                                                    )
                                                );
                                            }}
                                        >
                                            <option>Selecione uma cidade</option>
                                            {deliveryCities.map((item) => (
                                                <option
                                                    key={item.id}
                                                    selected={
                                                        order.delivery_address.city === item.name
                                                    }
                                                    value={item.name}
                                                    data-id={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>

                                    <Box width="100%">
                                        {deliveryMethod?.type === 'CITY' && (
                                            <Input
                                                name="neighborhood"
                                                label="Bairro"
                                                isRequired={
                                                    paymentMethod !== 'PAY_IN_STORE' &&
                                                    paymentMethod !== 'PAY_IN_DELIVERY'
                                                }
                                                size="sm"
                                                defaultValue={order.delivery_address.neighborhood}
                                            />
                                        )}

                                        {selectedDeliveryCity &&
                                            deliveryMethod?.type === 'DISTRICT' && (
                                                <Select
                                                    label="Bairro"
                                                    name="neighborhood"
                                                    size="sm"
                                                    isRequired={
                                                        paymentMethod !== 'PAY_IN_STORE' &&
                                                        paymentMethod !== 'PAY_IN_DELIVERY'
                                                    }
                                                    onChange={(e) => {
                                                        handleChangeDeliveryValue();

                                                        setNeighborhoodId(
                                                            e.target[
                                                                e.target.selectedIndex
                                                            ].getAttribute('data-id')
                                                        );
                                                    }}
                                                >
                                                    {deliveryDistricts.map((item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.name}
                                                            data-id={item.id}
                                                            selected={
                                                                String(item.name).trim() ===
                                                                String(
                                                                    order.delivery_address
                                                                        .neighborhood
                                                                ).trim()
                                                            }
                                                        >
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                    </Box>

                                    <Box width="100%">
                                        <Input
                                            name="street"
                                            label="Rua"
                                            isRequired={
                                                paymentMethod !== 'PAY_IN_STORE' &&
                                                paymentMethod !== 'PAY_IN_DELIVERY'
                                            }
                                            size="sm"
                                            defaultValue={order.delivery_address.street}
                                        />
                                    </Box>

                                    <Box width="100%">
                                        <Input
                                            name="number"
                                            label="Número"
                                            isRequired={
                                                paymentMethod !== 'PAY_IN_STORE' &&
                                                paymentMethod !== 'PAY_IN_DELIVERY'
                                            }
                                            size="sm"
                                            defaultValue={order.delivery_address.number}
                                        />
                                    </Box>

                                    <Box width="100%">
                                        <Input
                                            name="complement"
                                            label="Complemento"
                                            size="sm"
                                            defaultValue={order.delivery_address.complement}
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                        )}
                        <Flex width="48%" flexDirection="column">
                            <Text color="purple.500" fontSize="20px" mt="16px">
                                Dados do Destinatário
                            </Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                mt="8px"
                            >
                                <Box width="100%">
                                    <Input
                                        name="destinatary_name"
                                        label="Nome"
                                        isRequired
                                        size="sm"
                                        defaultValue={order.details.destinatary_name}
                                    />
                                </Box>

                                <Box width="100%">
                                    <Input
                                        name="destinatary_telephone"
                                        label="Telefone"
                                        isRequired
                                        size="sm"
                                        defaultValue={order.details.destinatary_telephone}
                                    />
                                </Box>

                                <Flex width="100%" mt="40px">
                                    <TextArea
                                        name="observations"
                                        label="Observações"
                                        mb="0px"
                                        defaultValue={order.details.observations}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="space-between" flexDirection="column">
                        <Text color="purple.500" fontSize="20px" mt="16px">
                            Agendar Entrega
                        </Text>

                        <Flex width="100%" mt="8px">
                            <Flex flexDirection="column" mr="24px">
                                <Button
                                    mr="24px"
                                    width="200px"
                                    backgroundColor="purple.500"
                                    borderRadius="2px"
                                    size="sm"
                                    height="32px"
                                    py="0px"
                                    fontSize="14px"
                                    display="flex"
                                    alignItems="center"
                                    _focus={{
                                        outline: 'none'
                                    }}
                                    onClick={() => setShowCalendar(!showCalendar)}
                                >
                                    <FiCalendar size={16} />
                                    <Text ml="8px">{format(deliveryDate, 'dd/MM/yyyy')}</Text>
                                </Button>

                                <Collapse width="100%" isOpen={showCalendar} display="flex">
                                    <Calendar
                                        isErrored={false}
                                        selectedDate={deliveryDate}
                                        handleDateChange={handleChangeDeliveryDate}
                                        handleMonthChange={undefined}
                                    />
                                </Collapse>
                            </Flex>

                            <Flex>
                                <Select
                                    width="200px"
                                    name="delivery_hour"
                                    backgroundColor="gray.500"
                                    placeholder="Horário"
                                    borderRadius="2px"
                                    size="sm"
                                    defaultValue={order.delivery_hour}
                                >
                                    <option value="Manhã">Manhã</option>
                                    <option value="Tarde">Tarde</option>
                                    <option value="Noite">Noite</option>
                                </Select>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Flex alignItems="center">
                            <Text color="purple.500" fontSize="20px" mt="16px">
                                Produtos
                            </Text>
                        </Flex>

                        <Flex width="100%" justifyContent="space-between" fontSize="14px" mt="16px">
                            <Flex width="100%" justifyContent="center" maxWidth="56px">
                                <Text>Foto</Text>
                            </Flex>

                            <Flex width="100%" justifyContent="center">
                                <Text>Nome</Text>
                            </Flex>

                            <Flex width="100%" justifyContent="center" maxWidth="80px">
                                <Text>QTD</Text>
                            </Flex>

                            <Flex width="100%" justifyContent="center" maxWidth="104px">
                                <Text>Valor</Text>
                            </Flex>

                            <Flex width="100%" justifyContent="center" maxWidth="104px">
                                <Text>Subtotal</Text>
                            </Flex>

                            <Flex width="100%" justifyContent="center" maxWidth="24px" />
                        </Flex>
                        <Divider width="100%" my="4px" />

                        {orderCart.products.map((product) => (
                            <Flex key={product.id} width="100%" flexDirection="column">
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    fontSize="12px"
                                    alignItems="center"
                                >
                                    <Flex width="100%" justifyContent="center" maxWidth="56px">
                                        <Image src={product.thumb_url} />
                                    </Flex>
                                    <Flex width="100%" justifyContent="center">
                                        <Text>{product.name}</Text>
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        maxWidth="80px"
                                        alignItems="center"
                                    >
                                        <PseudoBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            p="4px"
                                            backgroundColor="purple.500"
                                            color="white"
                                            cursor="pointer"
                                            onClick={() => handleChangeQuantity(product, 'REMOVE')}
                                        >
                                            <FiMinus />
                                        </PseudoBox>

                                        <Text px="8px" fontSize="16px">
                                            {product.quantity}
                                        </Text>

                                        <PseudoBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            p="4px"
                                            backgroundColor="purple.500"
                                            color="white"
                                            cursor="pointer"
                                            onClick={() => handleChangeQuantity(product)}
                                        >
                                            <FiPlus />
                                        </PseudoBox>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="104px">
                                        <Text>{CurrencyUtils.numberToCurrency(product.value)}</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="104px">
                                        <Text>
                                            {CurrencyUtils.numberToCurrency(
                                                product.value * product.quantity
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="24px">
                                        <PseudoBox
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            cursor="pointer"
                                            backgroundColor="purple.500"
                                            color="white"
                                            p="4px"
                                            title="Remover Produto"
                                            onClick={() => handleRemoveProduct(product)}
                                        >
                                            <FiTrash2 />
                                        </PseudoBox>
                                    </Flex>
                                </Flex>

                                <Divider width="100%" my="2px" />
                            </Flex>
                        ))}
                        <Flex width="100%" flexDirection="column">
                            <Collapse width="100%" isOpen={colappseBoxProducts} my="16px">
                                <Flex
                                    width="100%"
                                    height="100%"
                                    border="1px solid"
                                    borderColor="gray.500"
                                    flexDirection="column"
                                    overflowY="auto"
                                    maxHeight="400px"
                                    p="8px"
                                >
                                    <Flex width="100%">
                                        <Input
                                            name="search"
                                            backgroundColor="gray.500"
                                            placeholder="Pesquisar produto"
                                            borderRadius="2px"
                                            size="sm"
                                            mb="0px"
                                            onChange={(e) =>
                                                handleChangeProductSearch(e.currentTarget.value)
                                            }
                                            _focus={{
                                                outline: 'none'
                                            }}
                                        />

                                        <Button
                                            backgroundColor="green.500"
                                            borderRadius="2px"
                                            width="40px"
                                            size="sm"
                                            height="32px"
                                            p="0px"
                                            fontSize="12px"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <FiSearch size={16} />
                                        </Button>
                                    </Flex>

                                    {productsResults.map((product) => (
                                        <Flex
                                            key={product.id}
                                            mt="8px"
                                            width="100%"
                                            px="16px"
                                            flexDirection="column"
                                            cursor="pointer"
                                            border="2px solid"
                                            borderColor="gray.500"
                                            onClick={() => handleAddProduct(product)}
                                        >
                                            <ProductsResults product={product} />
                                        </Flex>
                                    ))}
                                </Flex>
                            </Collapse>
                            {colappseBoxProducts === false && (
                                <PseudoBox
                                    width="100%"
                                    py="4px"
                                    display="flex"
                                    justifyContent="center"
                                    border="1px dashed"
                                    borderColor="purple.500"
                                    cursor="pointer"
                                    onClick={() => setCollapseBoxProducts(true)}
                                >
                                    <Text fontSize="14px" color="purple.500">
                                        ADICIONAR PRODUTO
                                    </Text>
                                </PseudoBox>
                            )}
                        </Flex>
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Flex alignItems="center">
                            <Text color="purple.500" fontSize="20px" mt="16px">
                                Taxas e Descontos
                            </Text>
                        </Flex>
                        <Flex width="100%" mt="8px">
                            <Flex width="100%" alignItems="center" px="8px">
                                <Text fontSize="14px" width="88px" mr="24px">
                                    Desconto
                                </Text>

                                <InputCurrency
                                    width="144px"
                                    name="discount_value"
                                    backgroundColor="gray.500"
                                    placeholder="Valor do desconto"
                                    borderRadius="2px"
                                    size="sm"
                                    mb="4px"
                                    defaultValue={order.discount_value}
                                    onKeyUp={(e) => handleAddDescount(e.currentTarget.value)}
                                />
                            </Flex>

                            <Flex width="100%" alignItems="center" px="8px">
                                <Text fontSize="14px" width="88px" mr="24px">
                                    Acréscimo
                                </Text>

                                <InputCurrency
                                    width="144px"
                                    name="tax_value"
                                    backgroundColor="gray.500"
                                    placeholder="Valor do acréscimo"
                                    borderRadius="2px"
                                    size="sm"
                                    mb="0px"
                                    defaultValue={order.tax_value}
                                    onKeyUp={(e) => handleAddTax(e.currentTarget.value)}
                                />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Flex alignItems="center">
                            <Text color="purple.500" fontSize="20px" mt="16px">
                                Forma de Pagamento
                            </Text>
                        </Flex>
                        <Flex display="flex" width="100%" mt="8px" fontSize="14px">
                            <Flex
                                width="144px"
                                height="32px"
                                border="2px dashed"
                                borderColor="gray.800"
                                justifyContent="center"
                                py="4px"
                                px="16px"
                                cursor="pointer"
                                backgroundColor={
                                    paymentMethod === 'CREDITCARD' ? 'purple.500' : 'white'
                                }
                                color={paymentMethod === 'CREDITCARD' ? 'white' : 'purple'}
                                onClick={() => setPaymentMethod('CREDITCARD')}
                            >
                                <Text>Cartão</Text>
                            </Flex>

                            <Flex
                                width="144px"
                                height="32px"
                                border="2px dashed"
                                borderColor="gray.800"
                                justifyContent="center"
                                py="4px"
                                px="16px"
                                cursor="pointer"
                                backgroundColor={
                                    paymentMethod === 'DEPOSIT' ? 'purple.500' : 'white'
                                }
                                color={paymentMethod === 'DEPOSIT' ? 'white' : 'purple'}
                                onClick={() => setPaymentMethod('DEPOSIT')}
                            >
                                <Text>Depósito</Text>
                            </Flex>

                            <Flex
                                width="144px"
                                height="32px"
                                border="2px dashed"
                                borderColor="gray.800"
                                justifyContent="center"
                                py="4px"
                                px="16px"
                                cursor="pointer"
                                backgroundColor={paymentMethod === 'MONEY' ? 'purple.500' : 'white'}
                                color={paymentMethod === 'MONEY' ? 'white' : 'purple'}
                                onClick={() => setPaymentMethod('MONEY')}
                            >
                                <Text>Dinheiro</Text>
                            </Flex>

                            <Flex
                                width="144px"
                                height="32px"
                                border="2px dashed"
                                borderColor="gray.800"
                                justifyContent="center"
                                py="4px"
                                px="16px"
                                cursor="pointer"
                                backgroundColor={paymentMethod === 'LINK' ? 'purple.500' : 'white'}
                                color={paymentMethod === 'LINK' ? 'white' : 'purple'}
                                onClick={() => setPaymentMethod('LINK')}
                            >
                                <Text>Link</Text>
                            </Flex>

                            <Flex
                                width="144px"
                                height="32px"
                                border="2px dashed"
                                borderColor="gray.800"
                                justifyContent="center"
                                py="4px"
                                px="16px"
                                cursor="pointer"
                                backgroundColor={
                                    paymentMethod === 'PAY_IN_DELIVERY' ? 'purple.500' : 'white'
                                }
                                color={paymentMethod === 'PAY_IN_DELIVERY' ? 'white' : 'purple'}
                                onClick={() => setPaymentMethod('PAY_IN_DELIVERY')}
                            >
                                <Text>Na Entrega</Text>
                            </Flex>

                            <Flex
                                width="144px"
                                height="32px"
                                border="2px dashed"
                                borderColor="gray.800"
                                justifyContent="center"
                                py="4px"
                                px="16px"
                                cursor="pointer"
                                backgroundColor={
                                    paymentMethod === 'PAY_IN_STORE' ? 'purple.500' : 'white'
                                }
                                color={paymentMethod === 'PAY_IN_STORE' ? 'white' : 'purple'}
                                onClick={() => setPaymentMethod('PAY_IN_STORE')}
                            >
                                <Text>Pagar na Loja</Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="space-between" mt="24px" fontSize="14px">
                        <Flex justifyContent="center">
                            <Text mr="8px">Produtos:</Text>
                            <Text>{CurrencyUtils.numberToCurrency(orderCart.products_value)}</Text>
                        </Flex>
                        <Flex justifyContent="center">
                            <Text mr="8px">Entrega:</Text>
                            <Text>{CurrencyUtils.numberToCurrency(orderCart.delivery_value)}</Text>
                        </Flex>
                        <Flex justifyContent="center">
                            <Text mr="8px">Desconto:</Text>
                            <Text>{CurrencyUtils.numberToCurrency(orderCart.discount_value)}</Text>
                        </Flex>
                        <Flex justifyContent="center">
                            <Text mr="8px">Acréscimo:</Text>
                            <Text>{CurrencyUtils.numberToCurrency(orderCart.tax_value)}</Text>
                        </Flex>
                        <Flex justifyContent="center">
                            <Text mr="8px">Total:</Text>
                            <Text>{CurrencyUtils.numberToCurrency(orderCart.total_value)}</Text>
                        </Flex>
                    </Flex>

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
                        Editar
                    </Button>
                </Flex>
            )}
        </Form>
    );
};

export default OrdersEditPage;
