import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';

const UsersRegister: React.FC = () => {
    const formRef = useRef<FormHandles>(undefined);
    const { store } = useAuth();
    const { changeTitle } = useLayout();
    const navigate = useNavigate();

    const toast = useToast();

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                if (!formData.email || !formData.password) {
                    toast({
                        title: 'Campos obrigatório',
                        description: 'Informe o login e senha ',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }

                const response = await apiGateway.post('/users', {
                    name: formData.name,
                    email: formData.email,
                    password: formData.password,
                    permission: formData.permission,
                    store_id: store,
                    create_master: true
                });

                if (response.status === HTTP.STATUS.CREATED) {
                    FormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Cupon cadastrada com sucesso',
                        toast
                    );
                    FormHelper.reset(formRef as any);
                    navigate('/settings/users');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [store, navigate, toast]
    );

    useEffect(() => {
        changeTitle('Novo Usuário');
    }, []);

    return (
        <Form
            ref={formRef as any}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Dados do Usuário
                </Text>

                <Flex width="50%" flexDirection="column">
                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="48%">
                            <Input name="name" label="Nome" />
                        </Flex>
                        <Flex width="48%">
                            <Select name="permission" label="Permissão">
                                <option>Admin</option>
                                <option>Florista</option>
                                <option>Atendente</option>
                                <option>Caixa</option>
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="48%">
                            <Input name="email" label="Login" isRequired />
                        </Flex>
                        <Flex width="48%">
                            <Input name="password" label="Senha" isRequired />
                        </Flex>
                    </Flex>
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default UsersRegister;
