import { Collapse, Divider, Flex, Text } from '@chakra-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import { FiCalendar, FiCheck, FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

import { format } from 'date-fns';
import Button from '../../../components/Button';
import Calendar from '../../../components/Form/Calendar';
import PanelButton from '../../../components/PanelButton';
import { useLayout } from '../../../layouts/default';
import { HTTP } from '../../../shared/constants';
import apiGateway from '../../../shared/services/apiGateway';
import CurrencyUtils from '../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../shared/utils/DateUtils';

const ProductsRelatoryPage: React.FC = () => {
    const printComponentRef = useRef(undefined);

    const { changeTitle } = useLayout();

    const [showCalendar, setShowCalendar] = useState(false);
    const [periodDate1Filter, setPeriodDate1Filter] = useState(new Date());
    const [periodDate2Filter, setPeriodDate2Filter] = useState(new Date());
    const [statusFilter, setStatusFilter] = useState(null);

    const [products, setProducts] = useState([]);

    const handleChangePeriodDate1Filter = useCallback((date: Date, modifiers: DayModifiers) => {
        if (modifiers.available && !modifiers.disabled) {
            setPeriodDate1Filter(date);
        }
    }, []);

    const handleChangePeriodDate2Filter = useCallback((date: Date, modifiers: DayModifiers) => {
        if (modifiers.available && !modifiers.disabled) {
            setPeriodDate2Filter(date);
        }
    }, []);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    const handleSearch = useCallback(async () => {
        const response = await apiGateway.get('/relatories/products', {
            params: {
                date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd')),
                status: statusFilter
            }
        });

        if (response.status === HTTP.STATUS.SUCCESS) setProducts(response.data);

        setShowCalendar(false);
    }, [periodDate1Filter, periodDate2Filter, statusFilter]);

    const totalUnities = useMemo(() => {
        return products.reduce((prev, current) => {
            return prev + current.quantity;
        }, 0);
    }, [products]);

    const totalSale = useMemo(() => {
        return products.reduce((prev, current) => {
            return prev + current.total;
        }, 0);
    }, [products]);

    useEffect(() => {
        changeTitle('Relatórios');
    }, []);

    return (
        <Flex width="100%" flexDirection="column" backgroundColor="white" p="16px">
            <Flex width="100%">
                <Flex width="50%" justifyContent="flex-start" alignItems="center">
                    <Text mr="16px" fontSize="18px" color="purple.500">
                        {`Relatório de Venda por Produto no Período de ${format(
                            periodDate1Filter,
                            'dd/MM/yyyy'
                        )} até ${format(periodDate2Filter, 'dd/MM/yyyy')}`}
                    </Text>
                </Flex>
            </Flex>
            <Divider my="8px" />
            <Flex width="100%">
                <Flex width="50%" alignItems="center">
                    <Text width="96px" fontSize="12px">
                        Data de Entrega:
                    </Text>

                    <Flex flexDirection="column" mr="16px">
                        <Button
                            width="144px"
                            backgroundColor="purple.500"
                            borderRadius="2px"
                            size="sm"
                            height="32px"
                            py="0px"
                            fontSize="12px"
                            display="flex"
                            alignItems="center"
                            _focus={{
                                outline: 'none'
                            }}
                            _hover={{
                                backgroundColor: 'purple.500'
                            }}
                            onClick={() => setShowCalendar((oldState) => !oldState)}
                        >
                            <FiCalendar size={16} />
                            <Text ml="8px">{`De ${format(periodDate1Filter, 'dd/MM/yyyy')}`}</Text>
                        </Button>

                        <Collapse width="100%" isOpen={showCalendar} display="flex">
                            <Calendar
                                isErrored={false}
                                selectedDate={periodDate1Filter}
                                handleDateChange={handleChangePeriodDate1Filter}
                                handleMonthChange={undefined}
                            />
                        </Collapse>
                    </Flex>

                    <Flex flexDirection="column">
                        <Button
                            width="144px"
                            backgroundColor="purple.500"
                            borderRadius="2px"
                            size="sm"
                            height="32px"
                            py="0px"
                            fontSize="12px"
                            display="flex"
                            alignItems="center"
                            _focus={{
                                outline: 'none'
                            }}
                            _hover={{
                                backgroundColor: 'purple.500'
                            }}
                            onClick={() => setShowCalendar((oldState) => !oldState)}
                        >
                            <FiCalendar size={16} />
                            <Text ml="8px">{`Até ${format(periodDate2Filter, 'dd/MM/yyyy')}`}</Text>
                        </Button>

                        <Collapse width="100%" isOpen={showCalendar} display="flex">
                            <Calendar
                                isErrored={false}
                                selectedDate={periodDate2Filter}
                                handleDateChange={handleChangePeriodDate2Filter}
                                handleMonthChange={undefined}
                            />
                        </Collapse>
                    </Flex>
                </Flex>
            </Flex>
            <Flex width="100%" mt="8px">
                <Flex width="50%" alignItems="center">
                    <Text width="96px" fontSize="12px">
                        Status:
                    </Text>

                    <PanelButton
                        text="Todas"
                        isSelected={statusFilter === null}
                        buttonFunction={() => setStatusFilter(null)}
                    />

                    <PanelButton
                        text="Aprovadas"
                        isSelected={statusFilter === 'APROVADO'}
                        buttonFunction={() => setStatusFilter('APROVADO')}
                    />

                    <PanelButton
                        text="Pendentes"
                        isSelected={statusFilter === 'PENDENTE'}
                        buttonFunction={() => setStatusFilter('PENDENTE')}
                    />

                    <PanelButton
                        text="Negadas"
                        isSelected={statusFilter === 'NEGADO'}
                        buttonFunction={() => setStatusFilter('NEGADO')}
                    />
                </Flex>

                <Flex width="50%" justifyContent="flex-end">
                    <Button
                        width="104px"
                        backgroundColor="purple.500"
                        height="32px"
                        borderRadius="2px"
                        mr="16px"
                        _hover={{
                            backgroundColor: 'purple.500'
                        }}
                        _focus={{
                            backgroundColor: 'purple.500'
                        }}
                    >
                        <Flex alignItems="center" onClick={() => handlePrint()}>
                            <Text fontSize="14px" lineHeight="14px" mr="16px">
                                Imprimir
                            </Text>
                            <FiPrinter size={16} />
                        </Flex>
                    </Button>

                    <Button
                        width="104px"
                        backgroundColor="green.500"
                        height="32px"
                        borderRadius="2px"
                        onClick={() => handleSearch()}
                    >
                        <Flex alignItems="center">
                            <Text fontSize="14px" lineHeight="14px" mr="16px">
                                Buscar
                            </Text>
                            <FiCheck size={16} />
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
            <Divider my="8px" />
            <Flex ref={printComponentRef} width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        className="onlyPrint"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        my="8px"
                    >
                        <Text fontSize="18px">
                            {`Relatório de Venda por Produto no Período de ${format(
                                periodDate1Filter,
                                'dd/MM/yyyy'
                            )} até ${format(periodDate2Filter, 'dd/MM/yyyy')}`}
                        </Text>
                    </Flex>
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="12px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Código</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Produto</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text>Uni. Vendidas</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Total Vendido</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Data de Entrega</Text>
                        </Flex>
                    </Flex>
                </Flex>
                {products.map((product) => (
                    <Flex
                        key={product.id}
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>{product.code}</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="flex-start">
                            <Text>{product.name}</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text>{product.quantity}</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>{CurrencyUtils.numberToCurrency(product.total)}</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>{DateUtils.format(product.delivery_date)}</Text>
                        </Flex>
                    </Flex>
                ))}
                <Flex
                    width="100%"
                    py="8px"
                    justifyContent="space-between"
                    fontSize="16px"
                    maxWidth="100%"
                >
                    <Flex width="100%" justifyContent="center" maxWidth="104px"></Flex>

                    <Flex width="100%" justifyContent="center"></Flex>

                    <Flex width="100%" justifyContent="center" maxWidth="96px">
                        <Text>{`Total Uni.: ${Number(totalUnities).toFixed(0)}`}</Text>
                    </Flex>

                    <Flex width="100%" justifyContent="center">
                        <Text>{`Total Vendido: ${CurrencyUtils.numberToCurrency(totalSale)}`}</Text>
                    </Flex>

                    <Flex width="100%" justifyContent="center"></Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ProductsRelatoryPage;
