import React from 'react';

import { Button as ButtonChakra, ButtonProps as ButtonChakraProps } from '@chakra-ui/core';

const Button: React.FC<ButtonChakraProps> = ({ children, ...rest }) => {
    return (
        <ButtonChakra
            type="button"
            width="100%"
            height="100%"
            backgroundColor="red.500"
            borderRadius="0px"
            color="white"
            fontWeight="500"
            fontSize="24px"
            _hover={{
                backgroundColor: 'green.500'
            }}
            _focus={{
                outline: 'none'
            }}
            _active={{
                backgroundColor: 'green.500'
            }}
            {...rest}
        >
            {children}
        </ButtonChakra>
    );
};

export default Button;
