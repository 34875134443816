import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import InputCurrency from '../../../components/Form/InputCurrency';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';
import NumberUtils from '../../../shared/utils/NumberUtils';

const CuponsRegister: React.FC = () => {
    const formRef = useRef<FormHandles>(undefined);
    const [isEnable, setIsEnable] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.post('/cupons', {
                    name: formData.name,
                    type: isEnable ? 'PERCENTUAL' : 'FIXO',
                    value: NumberUtils.formatNumber(formData.value)
                });
                if (response.status === HTTP.STATUS.CREATED) {
                    FormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Cupon cadastrada com sucesso',
                        toast
                    );
                    FormHelper.reset(formRef as any);
                    navigate('/settings/cupons');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [isEnable, history, toast]
    );

    return (
        <Form
            ref={formRef as any}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Informações Básicas
                </Text>

                <Flex width="50%" flexDirection="column">
                    <Input name="name" label="Cupon" />

                    <Flex width="100%" justifyContent={'space-between'}>
                        <Flex width={'48%'} flexDirection="column">
                            <Box fontWeight="500" mr="48px">
                                <Text>Tipo de Desconto</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable === false}
                                        onChange={() => setIsEnable(!isEnable)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Fixo</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable}
                                        onChange={() => setIsEnable(!isEnable)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Percentual</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>

                        <Flex width={'48%'} flexDirection="column">
                            <InputCurrency name="value" label="Valor" />
                        </Flex>
                    </Flex>
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default CuponsRegister;
